import React from 'react';
import { IStyle, mergeStyles, Theme, useTheme } from '@fluentui/react';

export type BadgeColor =
	| 'default'
	| 'success'
	| 'info'
	| 'warning'
	| 'severeWarning'
	| 'error';

export type BadgeProps = React.ComponentPropsWithoutRef<'span'> & {
	className?: string;
	color?: BadgeColor;
};

const badgeStyle: IStyle = {
	padding: '0.35em 0.65em',
	fontWeight: '600', // This weight is tuned for the Robot font
	fontSize: '0.925em',
	borderRadius: '3px',
};

const getBackgroundColor = (theme: Theme, desiredColor: BadgeColor): string => {
	switch (desiredColor) {
		case 'success':
			return theme.semanticColors.successBackground;
		case 'info':
			return theme.semanticColors.infoBackground;
		case 'warning':
			return theme.semanticColors.warningBackground;
		case 'severeWarning':
			return theme.semanticColors.severeWarningBackground;
		case 'error':
			return theme.semanticColors.errorBackground;
		case 'default':
			return theme.semanticColors.defaultStateBackground;
		default:
			return 'transparent';
	}
};

export const Badge: React.FC<BadgeProps> = ({
	className,
	color = 'default',
	children,
	...htmlProps
}) => {
	const theme = useTheme();

	return (
		<span
			{...htmlProps}
			className={mergeStyles(
				badgeStyle,
				{
					backgroundColor: getBackgroundColor(theme, color),
				},
				className
			)}
			data-badge-color={color}
		>
			{children}
		</span>
	);
};

export default Badge;
