import { Message, Translator } from '@eo-locale/core';
import { logger } from '@ysoft/logger';

const log = logger.getLogger('@ysoft/react-intl');

function createIntl(initialConfig: IntlConfig): Intl {
	const subscribers: (Subscriber | undefined)[] = [];
	let locale: string = initialConfig.locale;
	let fallbackLocale: string | undefined = initialConfig.fallbackLocale;
	let countries: { [countryCode: string]: string } | undefined;
	let translator: Translator;
	let fallbackTranslator: Translator;

	return {
		subscribe(cb) {
			const length = subscribers.push(cb);
			const index = length - 1;
			return () => {
				subscribers[index] = undefined;
			};
		},
		init: load,
		async changeLocale(newLocale: string) {
			locale = newLocale;
			await load();
		},
		getCountry(countryCode: string) {
			if (!countries) {
				throw new Error('Translation for countries is not defined.');
			}
			return countries[countryCode];
		},
		translate(id, options) {
			const translation = translator.translate.bind(translator)(id, options);
			if (translation !== id) {
				return translation;
			}
      const fallbackTranslation = fallbackTranslator.translate.bind(
        fallbackTranslator
      )(id, options);
      if (fallbackTranslation !== id) {
        return fallbackTranslation;
      }
      return `{{${fallbackTranslation}}}`;
		},
		formatNumber(...args) {
			return translator.formatNumber.call(translator, ...args);
		},
		formatDate(...args) {
			return translator.formatDate.call(translator, ...args);
		},
		getMessageById(...args) {
			return translator.getMessageById.call(translator, ...args);
		},
		get currentLocale() {
			return locale;
		},
	};

	async function load() {
		let messages: { [key: string]: Message };
		let fallbackMessages: { [key: string]: Message };
		await Promise.all([
			loadMessages(initialConfig, locale).then((loadedMessages) => {
				messages = loadedMessages;
				translator = loadTranslator(locale, messages);
				translator.onError = (error) => {
					publish({
						type: 'WARNING',
						payload: error,
					});
				};
			}),
			loadMessages(initialConfig, fallbackLocale).then((loadedMessages) => {
				fallbackMessages = loadedMessages;
				fallbackTranslator = loadTranslator(locale, fallbackMessages);
				fallbackTranslator.onError = (error) => {
					publish({
						type: 'FAILED',
						payload: error,
					});
				};
			}),
			loadCountries(initialConfig, locale).then(
				(loadedCountries) => (countries = loadedCountries)
			),
		]).then(() => {
			publish({
				type: 'LOADED',
				payload: {
					locale,
					messages,
					countries,
				},
			});
		});
	}

	function publish(event: Event) {
		if (event.type === 'FAILED') {
			log.error(event);
		} else {
			log.info(event);
		}
		subscribers.forEach((cb) => {
			if (cb) {
				cb(event);
			}
		});
	}
}

const findCacheBustingParameter = (initialConfig: IntlConfig): string => {
	if ('locales' in initialConfig) {
		return '';
	} else {
		return initialConfig.version ? `?v=${initialConfig.version}` : '';
	}
};

async function loadMessages(
	initialConfig: IntlConfig,
	locale?: string
): Promise<{ [key: string]: Message }> {
	if (locale) {
		if ('locales' in initialConfig) {
			return initialConfig.locales[locale].messages;
		} else {
			return await fetch(
				`${
					initialConfig.localePath
				}/${locale}/translation.json${findCacheBustingParameter(initialConfig)}`
			).then((response) => response.json());
		}
	} else {
		return {};
	}
}

function loadTranslator(
	locale: string,
	messages: { [key: string]: Message }
): Translator {
	return new Translator(locale, [
		{
			language: locale,
			messages,
		},
	]);
}

async function loadCountries(
	initialConfig: IntlConfig,
	locale: string
): Promise<{ [countryCode: string]: string } | undefined> {
	if ('locales' in initialConfig) {
		return initialConfig.locales[locale].countries;
	} else {
		return await fetch(
			`${
				initialConfig.localePath
			}/${locale}/countries.json${findCacheBustingParameter(initialConfig)}`
		)
			.then((response) => {
				if (
					response.headers.get('Content-Type')?.includes('application/json')
				) {
					return response.json();
				} else {
					return { countries: undefined };
				}
			})
			.then((data) => data.countries)
			.catch(() =>
				Promise.resolve({
					json: () => Promise.resolve({ countries: undefined }),
				})
			);
	}
}

export { createIntl };

export type Intl = Pick<
	Translator,
	'translate' | 'formatNumber' | 'formatDate' | 'getMessageById'
> & {
	subscribe: (cb: Subscriber) => Unsubscribe;
	init: () => Promise<void>;
	changeLocale: (newLocale: string) => Promise<void>;
	getCountry: (countryCode: string) => string;
	currentLocale: string;
};

export type Event =
	| {
			type: 'LOADED';
			payload: {
				locale: string;
				messages: { [key: string]: Message };
				countries?: { [countryCode: string]: string };
			};
	  }
	| {
			type: 'FAILED' | 'WARNING';
			payload: Error;
	  };

type Subscriber = (event: Event) => void;
type Unsubscribe = () => void;

export type IntlConfig =
	| {
			locale: string;
			fallbackLocale?: string;
			localePath: string;
			version?: string;
	  }
	| {
			locale: string;
			fallbackLocale?: string;
			locales: {
				[locale: string]: {
					messages: { [key: string]: Message };
					countries?: { [countryCode: string]: string };
				};
			};
	  };
